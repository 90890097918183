  // app/javascript/controllers/expedited_modal_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "input", "form"];

  open(event) {
    event.preventDefault();

    const modal = document.querySelector('[data-order--expedited-modal-target="modal"]'); // Query globally for the modal
    if (modal) {
      modal.style.display = "block"; // Ensure modal is visible
      modal.classList.add("in"); // Add Bootstrap "in" class for showing the modal
      modal.setAttribute("aria-hidden", "false");
    } else {
      console.error("Modal element not found in the DOM.");
    }
  }

  close(event) {
    event.preventDefault();

    const modal = document.querySelector('[data-order--expedited-modal-target="modal"]'); // Query globally for the modal
    if (modal) {
      modal.style.display = "none"; // Hide the modal
      modal.classList.remove("in"); // Remove Bootstrap "in" class
      modal.setAttribute("aria-hidden", "true");
    } else {
      console.error("Modal element not found in the DOM.");
    }
  }

  submit(event) {
    event.preventDefault();

    const modal = document.querySelector('[data-order--expedited-modal-target="modal"]'); // Query globally for the modal
    const input = document.querySelector('[data-order--expedited-modal-target="input"]'); // Query globally for the input field

    if (!modal || !input) {
      console.error("Modal or input element not found in the DOM.");
      return;
    }

    const url = document.querySelector('[data-order--expedited-modal-url]').getAttribute('data-order--expedited-modal-url');
    const inHandsDate = input.value;

    if (!inHandsDate) {
      console.error("In Hands Date is required.");
      return;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        in_hands_date: inHandsDate,
        expedited: true,
      }),
    })
      .then((response) => {
        if (response.ok) {
          modal.style.display = "none"; // Hide the modal on success
          modal.classList.remove("in"); // Remove Bootstrap "in" class
          modal.setAttribute("aria-hidden", "true");
          window.location.reload(); // Reload the page
        } else {
          console.error("Failed to mark as expedited.");
        }
      })
      .catch((error) => console.error("Error:", error));
  }
}
